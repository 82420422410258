import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

const StyledContainer = styled.div`
 width:100%;
 height:100%;
`;

const defaultProps = {
  center: {
    lat: 32.253460,
    lng: -110.911789,
  },
  zoom: 11,
};

const MapPreview = () => (
  <StyledContainer>
    <GoogleMapReact
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    />
  </StyledContainer>
);

export default MapPreview;
