import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Link } from '@reach/router';

const StyledImage = styled.img`
  height: ${prop('height')};
  width: ${prop('width')};
  transform: ${prop('transform')};
  opacity: ${prop('opacity')};
`;

const Logo = ({
  height = '8rem',
  width = '8rem',
  opacity = '1',
  transform = 'none',
  alt = 'My Tucson contractor logo',
  image,
}) => (
  <Link to="/">
    <StyledImage
      height={height}
      width={width}
      opacity={opacity}
      transform={transform}
      src={image}
      alt={alt}
    />
  </Link>
);

export default Logo;
