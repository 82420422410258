import React, { Fragment } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import H2 from '../Elements/Heading2';
import FooterSubSection from './FooterSubSection';
import Link from '../Link';
import Logo from '../Logo';
import DisclaimerSection from '../DisclaimerSection';
import { Facebook, LinkedIn, Twitter } from '../SocialButton';
import MaxWidth from '../MaxWidth';

const StyledMainContainer = styled.div``;

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const StyledZigZagLine = styled.div`
  background: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  width: 100%;
  height: 5rem;
  margin-bottom: -2px;
  @media (min-width: ${prop('theme.screens.mobile', '820px')}) {
    height: 7rem;
    background-size: contain;
  }
  @media (min-width: ${prop('theme.screens.large', '1280px')}) {
    height: 10rem;
    background-size: contain;
  }
  @media (min-width: ${prop('theme.screens.large', '2100px')}) {
    height: 15rem;
    background-size: contain;
  }
`;

const StyledFooterContainer = styled.div`
  background-color: ${prop('theme.color.blue', '#0F0F3A')};
  padding-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const StyledMapAndListItems = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledMap = styled.div`
  width: 200px;
  height: 250px;
  margin-right: 40px;
  margin-bottom: 20px;
`;

const SocialMediaButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  & > a {
    margin-left: 1.5rem;
  }
`;

const StyledSecondPartOfFooter = styled.div`
  background-color: white;
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
`;

const StyledPrivacySection = styled.div`
  background-color: white;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  & > h2 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  & > p {
    line-height: 1.5;
  }
  @media (min-width: ${prop('theme.screens.large', '1280px')}) {
    width: 80%;
  }
`;

const FooterComponent = ({
  background,
  logo,
  isDisclaimerPresent = false,
  footerListResources,
  footerListGeneral,
  footerListCompanyInformation,
  mapImage,
}) => (
  <StyledMainContainer>
    <StyledZigZagLine background={background} />

    <StyledFooterContainer>
      <MaxWidth>
        <Flex>
          {isDisclaimerPresent === true ? (
            <DisclaimerSection />
          ) : (
            <>
              <FooterSubSection footer={footerListResources} />
              <FooterSubSection footer={footerListGeneral} />
            </>
          )}
          <StyledMapAndListItems>
            <StyledMap>
              <Link
                to="https://www.google.com/maps/place/4015+E+Paradise+Falls+Dr+STE+132,+Tucson,+AZ+85712/@32.2708026,-110.9093377,17z/data=!3m1!4b1!4m5!3m4!1s0x86d671fc3edeb927:0x38f95a6e99272f5c!8m2!3d32.2708026!4d-110.907149"
                target="_blank"
              >
                <img
                  src={mapImage}
                  alt="Street Map with Office Location"
                  width={200}
                  height={250}
                />
              </Link>
            </StyledMap>

            <FooterSubSection footer={footerListCompanyInformation} />
          </StyledMapAndListItems>
        </Flex>
      </MaxWidth>
    </StyledFooterContainer>

    {isDisclaimerPresent ? (
      <StyledPrivacySection>
        <H2>ACT and its members thank you for using our free service.</H2>
        <p>
          ACT Disclaimer: ACT does not provide construction service or advice.
          ACT does not endorse any of the referral members. The consumer is
          solely responsible for interviewing and selecting a suitable service
          provider. ACT, its agents and employees will not be liable to you for
          any loss or damages of any nature arising out of your use of ACT
          referrals.
        </p>
      </StyledPrivacySection>
    ) : null}
    <MaxWidth>
      <StyledSecondPartOfFooter>
        <div>
          <div>
            Copyright &#169;
            {new Date().getFullYear()}
            {' '}
            - ACT
          </div>
          <div>All rights reserved.</div>
        </div>
        <Logo image={logo} />
        <SocialMediaButtons>
          <Facebook link="https://www.facebook.com/ArizonaConstructionTrades/" />
          <Twitter link="https://twitter.com/AZConstrTrades" />
          <LinkedIn link="https://www.linkedin.com/company/arizonaconstructiontrades/" />
        </SocialMediaButtons>
      </StyledSecondPartOfFooter>
    </MaxWidth>
  </StyledMainContainer>
);

export default FooterComponent;
