import React from 'react';
import styled from 'styled-components';
import Link from '../Link';

const StyledPara = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
  margin: 10px 0px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: white;
  flex-grow: 1;

  
    padding-right: 1rem;
    padding-top: 5px;
  
`;

const StyledHeader = styled.h2`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: white;
`;

const StyledLink = styled.div`
  margin-bottom: 5px;
 
  a {
    color: white;
  }
`;

const RenderFooterListItems = ({ listItems = [] }) => listItems.map(({
  id, name, url, label,
}) => (
  <StyledPara key={id}>
    <div>{url === undefined ? name : label}</div>
    <StyledLink><Link to={url}><div>{url === undefined ? '' : `\u00A0${name}`}</div></Link></StyledLink>
  </StyledPara>
));

const FooterSubSection = (props) => {
  const renderFooterSubSection = ({ id, header, listItems }) => (
    <div key={id}>
      <StyledHeader>{header === '' ? <br /> : header}</StyledHeader>
      <div>
        <RenderFooterListItems listItems={listItems} />
      </div>
    </div>
  );

  return renderFooterSubSection(props.footer);
};

export default FooterSubSection;
