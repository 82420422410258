import React from 'react';
import styled from 'styled-components';
import NavBar from './NavBar';

const FlexTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const LogoNavMenuContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  margin: 0 auto;
  width: 50%;
  max-width: 800px;
  padding: 0 2em;
  flex: 2 1;

  @media (max-width: 800px) {
    flex: 1 auto;
    padding: 0 1em;
  }

  @media( min-width: 450px){
    order: -2;
  }
  @media (max-width: 500px) {
    padding: 0;

  }
`;

const HeaderItems = styled.div`
  flex: 200px 1 200px;
  display: flex;
  justify-content: flex-end;
  order: -1;
  flex-grow: 1;


`;

const StyledNav = styled(NavBar)`
justify-content: flex-end;
`;

const MobileOnly = styled.div`
  @media (min-width: 801px) {
    display: none;
  }
`;
const DesktopOnly = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const FlexWide = styled.div`
display: flex;
align-items: center;
`;

const Nav = ({
  className, preNav, navData, postNav, maintenanceMode
}) => {
  // const navData = [];
  const subNav = [];

  return (
    <>
      <MobileOnly>
        <FlexTop>
          <LogoNavMenuContainer>
            {preNav}
          </LogoNavMenuContainer>
          <NavBar className={className} isMobileMenu navData={navData} />
          <HeaderItems>
            {postNav}
          </HeaderItems>
        </FlexTop>
      </MobileOnly>

      <DesktopOnly>
        <FlexWide>
          {preNav}
          <StyledNav className={className} navData={navData} subNav={subNav} />
          {postNav}
        </FlexWide>
      </DesktopOnly>
    </>
  );
};

export default Nav;
