import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { Button, Link } from '@allthingswww/client-act-shared';
import Image from './Image';
import H1 from './StyledH1';

const StyledHeaderContainer = styled.div`
  H1 {
    text-shadow: 3px 3px #1c1609;
    @media (max-width: 800px) {
      margin-bottom: 1rem;
    }
  }
  width: 100%;
  top: 0;
  display: flex;
  ${(props) => (props.condensed ? 'min-height: 10rem;' : 'min-height: 60vh;')}

  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  line-height: 61px;
  color: #ffffff;
  position: relative;
`;

const StyledPara = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 36px;
  text-shadow: 2px 2px #1c1609;
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  align-items: center;
  text-align: center;
  color: #ffffff;

  @media all and (max-width: 1280px) {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 10px;
  }
`;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
  ${ifProp(
    'headerBackGroundImage',
    css`
      /* background: url(${prop('headerBackGroundImage')}) no-repeat center center; */
      background-size: cover;
      background-attachment: local;
      background-position: center;
      min-width: 100%;
      clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 93%,
        83% 89%,
        60% 97%,
        25% 93%,
        0 100%
      );
    `,
  )}
  overflow: hidden;
  @media (max-width: 400px) {
    padding-bottom: 40px;
  }
`;

const StyledButton = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-flow: column nowrap;

  @media (max-width: 1060px) {
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  & > p {
    margin-top: 0.5rem;
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (max-width: 400px) {
    margin-top: 1rem;
  }
`;

const ImgWrapper = styled.div`
  position: absolute;
  z-index: -1;
  img {
    max-width: 100vw;
  }
`;

const TitleAndDescription = ({
  headerBackGroundImage = '',
  title,
  description,
  button,
  buttonTarget,
  condensed,
}) => (
  <div>
    <StyledMainContainer headerBackGroundImage={headerBackGroundImage}>
      <StyledHeaderContainer
        headerBackGroundImage={headerBackGroundImage}
        condensed={condensed}
      >
        {headerBackGroundImage.search(':') === -1 && (
          <ImgWrapper>
            <Image
              fileName={headerBackGroundImage}
              alt="Construction drawing"
              height={600}
              width="100vw"
            />
          </ImgWrapper>
        )}
        <H1 light>{title}</H1>
        {description && <StyledPara>{description}</StyledPara>}
        {button && (
          <StyledButton>
            <Link to={buttonTarget}>
              <Button.Button variant to={buttonTarget}>
                {button}
              </Button.Button>
            </Link>
          </StyledButton>
        )}
      </StyledHeaderContainer>
    </StyledMainContainer>
  </div>
);

export default TitleAndDescription;
