/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styled, { ThemeProvider } from 'styled-components';
import { Location, useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import {
  Logo,
  Footer,
  ErrorBoundary,
  Seo,
  MaxWidth,
  PhoneButton,
  Button,
} from '@allthingswww/client-act-shared';
import { getAuthenticated } from '../redux/user';

import TitleAndDescription from './TitleAndDescription';
import { footerNavItems } from '../assets/nav';
import GlobalStyles from './GlobalStyle';
import theme from '../assets/theme';
import logo from '../images/RecreatedLogo.svg';
import up from '../images/Up.png';
import Favicon from './Favicon';

import Nav from './Nav/index';

const query = graphql`
  query SEO {
    allMdx {
      nodes {
        id
        frontmatter {
          slug
          id
          subtitle
          featuredImage
          nav {
            id
            order
            title
            parent
            url
            target
            banner {
              slug
              position
              image
              title
              subtitle
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        showFeedBack
        stripePublicKey
        deployPrimeUrl
      }
    }
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "logo.png" }
      }
    ) {
      edges {
        node {
          id
          relativePath
          publicURL
        }
      }
    }
  }
`;

const AppContainer = styled.main`
  display: flex;
  flex-direction: column;
  min-height: 90vh;

  /* TODO: Temporary Limit the Width */

  max-width: 2048px;
    margin: auto;
    border: 1px solid #0F0F3A33;
    border-top: 0;
    border-bottom: 0;
`;

const ApplyWaterMark = styled.div`
  overflow: hidden;
  padding: 25px 0;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 88%;
    height: 200%;
    top: 0rem;
    bottom: 0rem;
    background-image: url(${logo});
    background-size: 400vw;
    opacity: 0.05;
    z-index: -1;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    transform: rotate(-10deg);
    overflow: hidden;
    @media (max-width: 900px) {
      width: 75%;
    }
    @media (max-width: 500px) {
      width: 60%;
    }
  }
`;

const StyledLogo = styled(Logo)`
  justify-content: left;
  @media (min-width: 450px) {
    order: -2;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: right;

  justify-content: right;
  & > div {
    margin: 10px 0 10px 17px;
    text-align: right;
  }
`;

const phoneNumber = '(520) 624-3002';

const StyledPhoneButton = styled(PhoneButton)`
  @media (max-width: 450px) {
    width: 50vw;
    justify-content: center;
  }
`;

const StyledDashboard = styled(Button.Primary)`
  @media (max-width: 1176px) {
    display: none;
  }
`;

const StyledNav = styled(Nav)`
  flex-grow: 1;
  display: ${(props) => (props.maintenanceMode ? 'none' : 'block')};
`;

const StyledMaintenance = styled.div`
  text-align: center;
  margin: 16px 0;
  font-size: 40px;
`;
const defaultMaintenanceMode = false;
const defaultMaintenanceModeMessage = 'Great Things are Coming!';
const defaultImage = 'HeroImage4k.png';

const Layout = ({
  title = 'Title',
  subtitle = 'Sub Title',
  button,
  buttonTarget,
  icon,
  headerBackGroundImage = defaultImage,
  children,
  condensed,
  meta,
  maintenanceMode = defaultMaintenanceMode,
  maintenanceModeMessage = defaultMaintenanceModeMessage,
}) => {
  const { allMdx, site, allFile } = useStaticQuery(query);
  const isUserLoggedIn = useSelector(getAuthenticated);
  const { stripePublicKey, deployPrimeUrl } = site.siteMetadata || {};

  const right = (
    <Flex>
      <StyledDashboard href="/members">
        {isUserLoggedIn ? 'Dashboard' : 'Members'}
      </StyledDashboard>
      <StyledPhoneButton phoneNumber={phoneNumber} />
    </Flex>
  );
  const metaCopy = _.cloneDeep(meta);

  const stripePromise = loadStripe(stripePublicKey);

  if (
    metaCopy
    && metaCopy.data
    && metaCopy.data.page
    && metaCopy.data.page.body
  ) {
    // eslint-disable-next-line no-param-reassign
    delete metaCopy.data.page.body;
  }
  const imageURL = `${deployPrimeUrl}${allFile.edges[0].node.publicURL}`;
  const image = headerBackGroundImage.length > 1 ? headerBackGroundImage : defaultImage;
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta property="og:image" content={imageURL} />
        <meta name="twitter:image" content={imageURL} />
      </Helmet>
      <Elements stripe={stripePromise}>
        <AppContainer>
          {/* {showFeedBack && <FeedbackCollector meta={metaCopy} />} */}
          <ErrorBoundary>
            <Favicon />
            <Seo site={site} />
            <GlobalStyles />
            <MaxWidth>
              <Location>
                {(route) => (
                  <StyledNav
                    maintenanceMode={maintenanceMode}
                    navData={allMdx.nodes}
                    preNav={<StyledLogo height="8rem" width="16rem" image={logo} />}
                    postNav={right}
                    {...route}
                  />
                )}
              </Location>
            </MaxWidth>
          </ErrorBoundary>
          <ErrorBoundary>
            <TitleAndDescription
              condensed={condensed}
              title={title}
              description={subtitle}
              icon={icon}
              headerBackGroundImage={image}
              button={button}
              buttonTarget={buttonTarget}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <ApplyWaterMark>
              <MaxWidth>
                {maintenanceMode ? (
                  <StyledMaintenance>
                    {maintenanceModeMessage}
                  </StyledMaintenance>
                ) : (
                  children
                )}
              </MaxWidth>
            </ApplyWaterMark>
          </ErrorBoundary>
          <ErrorBoundary>
            {maintenanceMode ? null : (
              <Footer
                background={up}
                logo={logo}
                isDisclaimerPresent={false}
                items={footerNavItems}
                footerListResources={footerNavItems.footerListResources}
                footerListGeneral={footerNavItems.footerListGeneral}
                footerListCompanyInformation={
                  footerNavItems.footerListCompanyInformation
                }
                mapImage="https://ucarecdn.com/d1eecebf-4c8f-4d1c-be18-bc62ef344653/act_map.png"
              />
            )}
          </ErrorBoundary>
        </AppContainer>
      </Elements>
    </ThemeProvider>
  );
};

export default Layout;
