/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import PropTypes from 'prop-types';

const StyledContainer = styled.div`
  ${ifProp(
    'height',
    css`
      height: ${prop('height', '100%')};
    `,
    css`
      height: 100%;
    `,
  )};
  ${ifProp(
    'width',
    css`
      width: ${prop('width', '100%')};
    `,
    css`
      width: 100%;
    `,
  )};
  padding: 0rem;
  & img {
    height: ${prop('height', '100%')};
    width: ${prop('width', '100%')};
    max-width: 100vw;
  }
`;

const ImageComponent = ({
  fileName,
  height,
  width,
  className,
  alt = '',
  loading = 'lazy',
  ...remainingProps
}) => {
  if (fileName && fileName.search(':') > -1) {
    return (
      <img
        src={fileName}
        className={className}
        height={height}
        width={width}
        alt={alt}
        loading={loading}
        {...remainingProps}
      />
    );
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          allImageSharp {
            edges {
              node {
                fluid(maxWidth: 3840) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const tempFileName = fileName || 'HereImage4k.png';
        let fluid = data.allImageSharp.edges.find(
          (element) => element.node.fluid.src.split('/').pop() === tempFileName,
        );
        if (fluid) fluid = fluid.node.fluid;
        return (
          <StyledContainer height={height} width={width}>
            <Img
              fluid={fluid}
              alt={alt}
              style={{
                height,
                width,
              }}
            />
          </StyledContainer>
        );
      }}
    />
  );
};

export default ImageComponent;

ImageComponent.propTypes = {
  fileName: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

ImageComponent.defaultProps = {
  height: '',
  width: '',
};
