import React from 'react';
import { Link } from 'gatsby';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
// import { getStyles } from 'masterpiece-ui';

import NavItem from './NavItem';
import Image from '../ImageMdx';
/* ${getStyles('Nav.SubNavItemLink')}
${ifProp(
    { isMobile: true },
    getStyles('Nav.MobileSubNavItemLink'),
  )} */
const SubNavItem = styled.li`
  ${css(themeGet('components.Nav.SubNavItem'))}
  
  ${ifProp(
    { isMobile: true },
    css`
    ${css(themeGet('components.Nav.MobileSubNavItem'))};
    `,
  )}
  `;

const SubNavItemLink = styled(Link)`


${css(themeGet('components.Nav.SubNavItemLink'))}

${ifProp(
    { isMobile: true },
    css`
  ${css(themeGet('components.Nav.MobileSubNavItemLink'))};
  `,
  )}
`;

const handleSubMenuToggle = (event) => {
  if (event.target.classList.contains('is-open')) {
    event.target.classList.remove('is-open');
  } else {
    event.target.classList.add('is-open');
  }
};

export const NavItems = ({ navData, isMobileMenu, navClassName }) => {
  const { navItems, navOrder } = navData;
  const navTopItems = navOrder.map((navOrderItem) => {
    const {
      title, slug, hasSubNav, banner, url, target,
    } = navItems[navOrderItem];
    return (
      <NavItem
        target={target}
        id={`Link_${slug}`}
        isMobileMenu={isMobileMenu}
        navItem={navItems[navOrderItem]}
        key={navOrderItem}
        title={title}
        slug={slug}
        hasSubNav={hasSubNav}
        banner={banner}
        url={url}
      />
    );
  });
  return (
    <ul className={`${navClassName}-NavItemList`}>
      {navTopItems}
    </ul>
  );
};
export const SubNavItems = ({ subNavData, navClassName, isMobileMenu }) => {
  const subNavItems = subNavData.subNavOrder.map((navOrderItem) => {
    const { title, slug } = subNavData.subNavItems[navOrderItem];
    return (
      <SubNavItem id={`SubNavItem_${slug}`} isMobileMenu={isMobileMenu} key={navOrderItem} className={`${navClassName}-SubNavItem`}>
        <SubNavItemLink id={`SubNavItemLink_${slug}`} isMobileMenu={isMobileMenu} className={`${navClassName}-SubNavItemLink`} to={`/${slug}`}>{title}</SubNavItemLink>
      </SubNavItem>
    );
  });
  return (
    <ul className={`${navClassName}-SubNavItemList`}>
      {subNavItems}
    </ul>
  );
};
export const BannerItem = ({ banner, isMobileMenu, navClassName }) => {
  if (isMobileMenu) {
    return null;
  }
  return (
    <div className={`${navClassName}-SubNavBanner`}>
      <Link className={`${navClassName}-SubNavBannerLink`} to={`/${banner.slug}`}>
        <Image
          className={`${navClassName}-SubNavBannerImage`}
          src={banner.image}
          alt=""
          width="154"
          height="85"
        />
      </Link>
      <span className={`${navClassName}-SubNavBannerTitle`}>{banner.title}</span>
      <span className={`${navClassName}-SubNavBannerTitlesubtitle`}>{banner.subtitle}</span>
    </div>
  );
};
