/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Menu, X } from 'react-feather';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { getStyles } from 'masterpiece-ui';

import './Nav.css';
import createNavObj from './createNavObj';
import { NavItems } from './renderNavItems';

const StyledNav = styled.nav`
  ${getStyles('Nav')()}

  ${ifProp({ active: true }, css`
  ${getStyles('Nav.active')()}

  `)}

`;
const MenuButtonContainer = styled.div`
    text-align: right;
`;
const StyledMenuButton = styled.button`
${ifProp({ active: false }, css`
    order:1;
  `)}
  ${getStyles('Nav.MenuButton')()}

  ${ifProp({ active: true }, css`
  ${getStyles('Nav.MenuButtonActive')()}
    
  `)}
`;

const NavBar = ({
  navData, className, isMobileMenu, Link,
}) => {
  const NavObj = createNavObj(navData);
  const navClassName = isMobileMenu ? 'MobileNav' : 'Nav';
  const [active, setActive] = useState(false);
  const handleMenuToggle = () => setActive(!active);

  const MobileNav = (
    <MenuButtonContainer className="MobileNav-MenuButtonContainer">
      <StyledMenuButton active={active} tabIndex={0} aria-label="Mobile Nav Menu" className="Button-blank MobileNav-MenuButton" onClick={handleMenuToggle}>
        {active ? <X /> : <Menu />}
      </StyledMenuButton>
      {active ? (
        <NavItems
          Link={Link}
          navData={NavObj}
          isMobileMenu={isMobileMenu}
          navClassName={navClassName}
        />
      ) : null}
    </MenuButtonContainer>
  );

  const Nav = (
    <div className={`${navClassName}-InnerWrapper`}>
      <NavItems
        Link={Link}
        navData={NavObj}
        isMobileMenu={isMobileMenu}
        navClassName={navClassName}
      />
    </div>
  );

  return (
    <StyledNav active={active} className={`${className} ${navClassName} ${active ? 'is-open' : 'is-closed'}`}>
      {isMobileMenu ? MobileNav : Nav }
    </StyledNav>
  );
};

export default NavBar;
