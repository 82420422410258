import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const StyledH3 = styled.h3`
  font-size: ${prop('theme.heading.h3.fontSize', '35px')};
  color: ${(prop('theme.textStyles.heading.h3.color'), '#444444')};
  font-family: ${prop('theme.textStyles.heading.h3.fontFamily', 'Montserrat')};
  font-weight: ${prop('theme.textStyles.heading.h3.fontWeight', "'400'")};
  margin-bottom: 2rem;
`;

const H3 = ({ children, className }) => <StyledH3 className={className}>{children}</StyledH3>;

export default H3;
