import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import NavItem from './NavItem';

const actNavItems = [
  { id: 1, name: 'Home', href: '/' },
  { id: 2, name: 'EZ Permits', href: 'https://www.ezpermitting.org/' },
  { id: 3, name: 'Membership', href: '/membership' },
  { id: 4, name: 'Events', href: '/events' },
  { id: 5, name: 'Resources', href: '/resources' },
  { id: 6, name: 'About', href: '/about' },
  { id: 7, name: 'Contact Us', href: '/contact-us' },
];

const StyledNavigationBar = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;

  @media screen and (max-width: ${prop('theme.screens.mobile', '820px')}) {
    display: none;
  }
`;

const NavigationBar = ({
  navItems = actNavItems,
  currentItem,
  handleChangePage,
}) => (
  <StyledNavigationBar>
    {navItems.map((item) => (
      <NavItem
        item={item}
        isCurrentItem={item.href === currentItem}
        key={item.id}
        handleChangePage={handleChangePage}
      />
    ))}
  </StyledNavigationBar>
);

export default NavigationBar;
