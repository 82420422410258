import React from 'react';
import GenerateIcon from '../../tools/generateSvg';

const LinkedInIcon = () => (
  <GenerateIcon>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1328 16.0455H12.5966V10.9321C12.5966 9.59374 12.0434 8.68005 10.8271 8.68005C9.89669 8.68005 9.37928 9.29663 9.13846 9.89086C9.04815 10.1041 9.06226 10.4013 9.06226 10.6984V16.0455H5.55896C5.55896 16.0455 5.60412 6.98773 5.55896 6.16438H9.06226V7.71514C9.26922 7.03709 10.3887 6.06938 12.1751 6.06938C14.3915 6.06938 16.1328 7.49067 16.1328 10.5512V16.0455ZM2.01616 4.92843H1.99358C0.864703 4.92843 0.132812 4.17308 0.132812 3.21561C0.132812 2.23952 0.88634 1.5 2.0378 1.5C3.18832 1.5 3.89575 2.23766 3.91833 3.21282C3.91833 4.17028 3.18832 4.92843 2.01616 4.92843ZM0.536387 6.16438H3.65492V16.0455H0.536387V6.16438Z"
      fill="white"
    />
    <mask
      id="linkedInconMask"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="17"
      height="16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1328 16.0455H12.5966V10.9321C12.5966 9.59374 12.0434 8.68005 10.8271 8.68005C9.89669 8.68005 9.37928 9.29663 9.13846 9.89086C9.04815 10.1041 9.06226 10.4013 9.06226 10.6984V16.0455H5.55896C5.55896 16.0455 5.60412 6.98773 5.55896 6.16438H9.06226V7.71514C9.26922 7.03709 10.3887 6.06938 12.1751 6.06938C14.3915 6.06938 16.1328 7.49067 16.1328 10.5512V16.0455ZM2.01616 4.92843H1.99358C0.864703 4.92843 0.132812 4.17308 0.132812 3.21561C0.132812 2.23952 0.88634 1.5 2.0378 1.5C3.18832 1.5 3.89575 2.23766 3.91833 3.21282C3.91833 4.17028 3.18832 4.92843 2.01616 4.92843ZM0.536387 6.16438H3.65492V16.0455H0.536387V6.16438Z"
        fill="white"
      />
    </mask>
    <g mask="url(#linkedInconMask)">
      <rect x="0.132812" y="0.5" width="16" height="16" fill="white" />
    </g>
  </GenerateIcon>
);

export default LinkedInIcon;
