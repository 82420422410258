import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset-advanced';

const GlobalStyle = createGlobalStyle`
  ${reset};

  *, {
    user-select: auto;
  }

  body {
    font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', sans-serif !important;
    font-weight: 500;
  }
`;

export default GlobalStyle;
