const createNavObj = (navData) => {
  const finalNavObj = {
    navOrder: [],
    navItems: {},
  };

  const topNavItemsArray = navData.reduce((filteredData, currentItem) => {
    if (!currentItem || !currentItem.frontmatter || !currentItem.frontmatter.nav) {
      return filteredData;
    }

    const {
      id, title, order, parent,
    } = currentItem.frontmatter.nav;

    // check all needed props exist, and has no parent
    if (id && title && order && parent === '') {
      filteredData.push(currentItem);
    }

    return filteredData;
  }, []);

  // reorder them based on nav.order
  topNavItemsArray.sort((a, b) => ((a.frontmatter.nav.order > b.frontmatter.nav.order) ? 1 : -1));

  // populate finalNabObj with topNavItems and navOrder
  // TODO Refactor without side effects
  // eslint-disable-next-line array-callback-return
  topNavItemsArray.map((topNavItem) => {
    const topNavItemId = topNavItem.frontmatter.nav.id;

    finalNavObj.navOrder.push(topNavItemId);

    finalNavObj.navItems = {
      ...finalNavObj.navItems,
      [topNavItemId]: {
        ...topNavItem.frontmatter.nav,
        slug: topNavItem.frontmatter.slug,
        hasSubNav: false,
        subNav: {},
      },
    };
  });

  // populate each topNavItem in the finalNavObj with their subNavItems
  // TODO Refactor without side effects
  // eslint-disable-next-line array-callback-return
  topNavItemsArray.map((currentTopNavItem) => {
    const currentTopNavItemId = currentTopNavItem.frontmatter.nav.id;

    // go through navdata and get all of the subnav items for the currentTopNavItem
    const subNavItemsArray = navData.reduce((filteredSubNavItems, currentSubNavItem) => {
      if (!currentSubNavItem
        || !currentSubNavItem.frontmatter
        || !currentSubNavItem.frontmatter.nav) {
        return filteredSubNavItems;
      }

      const {
        id, title, order, parent,
      } = currentSubNavItem.frontmatter.nav;

      // check all needed props exist, the subnav parent id matches the topNavItem id
      if (parent === currentTopNavItemId && id && title && order) {
        filteredSubNavItems.push(currentSubNavItem);
      }

      return filteredSubNavItems;
    }, []);

    subNavItemsArray.sort((a, b) => ((a.frontmatter.nav.order > b.frontmatter.nav.order) ? 1 : -1));

    // populate finalNabObj with subNavItems, subNavOrder, and set hasSubNav to true
    const subNavOrder = [];
    // TODO Refactor without side effects
    // eslint-disable-next-line array-callback-return
    subNavItemsArray.map((subNavItem) => {
      const subNavItemId = subNavItem.frontmatter.nav.id;

      subNavOrder.push(subNavItemId);

      finalNavObj.navItems[currentTopNavItemId] = {
        ...finalNavObj.navItems[currentTopNavItemId],
        hasSubNav: true,
        subNav: {
          ...finalNavObj.navItems[currentTopNavItemId].subNav,
          subNavOrder,
          subNavItems: {
            ...finalNavObj.navItems[currentTopNavItemId].subNav.subNavItems,
            [subNavItemId]: {
              ...subNavItem.frontmatter.nav,
              slug: subNavItem.frontmatter.slug,
            },
          },
        },
      };
    });
  });

  return finalNavObj;
};

export default createNavObj;
