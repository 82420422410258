import styled from 'styled-components';

const MaxWidth = styled.div`
  margin: auto;
  width: 90vw;
  ${(props) => {
    if (props.width) {
      return `max-width: ${props.width}`;
    }
    return `
  max-width: 1500px;

  @media (max-width: 1920px){
    max-width: 1500px;
  }
  @media (max-width: 1200px){
    max-width: 1060px;
  }
  @media (max-width: 800px){
    max-width: 740px;
  }
  
  

`;
  }}
`;
export default MaxWidth;
