import React, { useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import Logo from '../Logo';
import Button from '../Button';

import Nav from './NavigationBar';

import MobileViewMenu from './MobileViewMenu';
import MaxWidth from '../MaxWidth';

const StyledHeaderLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.5rem;
  background-color: white;
  /* @media (min-width: ${prop('theme.screens.mobile', '820px')}) {
    padding: 0rem 1%;
  }
  @media (min-width: ${prop('theme.screens.large', '1280px')}) {
    padding: 0rem 20%;
  }
  @media (min-width: ${prop('theme.screens.xlarge', '2100px')}) {
    padding: 0rem 33%;
  } */
`;

const StyledHamburger = styled.div`
  @media (min-width: ${prop('theme.screens.mobile', '820px')}) {
    display: none;
  }
`;

const StyledButton = styled.div`
  padding: 0 0.7rem;
  margin-right: 1rem;
  @media (min-width: ${prop('theme.screens.mobile', '820px')}) {
    display: none;
  }
  @media (max-width: ${prop('theme.screens.small', '320px')}) {
    display: none;
  }
`;

const StyledNavRight = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: ${prop('theme.screens.mobile', '820px')}) {
    display: none;
  }
`;

const StyledNavLeft = styled.div`
  display: flex;
  flex-grow: 3;
  & > * {
    padding: 0rem 0.25rem;
  }
`;

const HeaderLayout = ({
  logo, hamburgerClose, hamburger, navItems, right,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function handleToggleNavbar() {
    return setIsOpen(!isOpen);
  }

  if (isOpen) {
    return (
      <MobileViewMenu
        handleToggleNavbar={handleToggleNavbar}
        navItems={navItems}
        logo={logo}
        hamburgerClose={hamburgerClose}
      />
    );
  }

  return (
    <MaxWidth>
      <StyledHeaderLayout>
        <StyledNavLeft>
          <Logo
            height="7rem"
            width="7rem"
            alt="My Tucson contractor logo"
            image={logo}
          />

          <Nav navItems={navItems} />
        </StyledNavLeft>

        <StyledNavRight>
          {right}
        </StyledNavRight>

        <StyledButton>
          <Button.Secondary href="/emergency">Emergency</Button.Secondary>
        </StyledButton>
        <StyledHamburger onClick={handleToggleNavbar}>
          <img
            height="35rem"
            width="35rem"
            src={hamburger}
            alt="Hamburger Menu Icon"
          />
        </StyledHamburger>
      </StyledHeaderLayout>
    </MaxWidth>
  );
};

export default HeaderLayout;
