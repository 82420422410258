import React from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
  height: 35px;
  background: transparent;
  color: black;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  margin-left: 10px;
  border-bottom: 1px solid black;
  min-width: 10rem;

  & > option {
    color: black;
    background: transparent;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const DropDown = ({
  options = [],
  name = '',
  onChange,
  value = '',
  placeholder,
  disabled = true,
}) => {
  const filteredOptions = options.filter((option) => option.name !== '');
  return (
    <StyledSelect onChange={onChange} value={value} name={name}>
      <option disabled={disabled} value="">
        {placeholder}
      </option>
      {filteredOptions.map((option) => (
        <option key={option.id} value={option.value}>
          {option.name}
        </option>
      ))}
    </StyledSelect>
  );
};

export default DropDown;
