import React from 'react';
import {
  ErrorBoundary,
  withErrorBoundary as withErrorBoundaryProper,
} from 'react-error-boundary';
import * as Sentry from '@sentry/browser';

const SENTRY_URL = 'https://afb83f13ee9c4bc793a67d9537fd876d@o552201.ingest.sentry.io/5678771';

Sentry.init({
  dsn: SENTRY_URL,
});

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary} type="button">
        Try again
      </button>
    </div>
  );
}

const doNothing = () => null;

const reportError = (error, info) => {
  Sentry.configureScope((scope) => {
    Object.keys(info).forEach((key) => {
      scope.setExtra(key, info[key]);
    });
  });
  Sentry.captureException(error);
};

const ErrorBoundaryComponent = ({
  children, FallbackComponent, onReset, onError,
}) => (
  <ErrorBoundary
    FallbackComponent={FallbackComponent || ErrorFallback}
    onReset={onReset || doNothing}
    onError={onError || reportError}
  >
    {children}
  </ErrorBoundary>
);

export const withErrorBoundary = (Component, config = {}) => withErrorBoundaryProper(Component, {
  ...config,
  FallbackComponent: config.FallbackComponent || ErrorFallback,
  onError: config.onError || doNothing,
  onReset: config.onReset || doNothing,
});

export default ErrorBoundaryComponent;
