import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import Link from './Link';
import { FacebookIcon, TwitterIcon, LinkedInIcon } from './assets/icons';

const StyleSocialButton = styled.button`
  color: ${prop('theme.color.white', '#fff')};
  background: ${prop('fillColor', '')};
  min-height: 30px;
  min-width: 90px;
  text-align: center;
  font-size: ${prop('theme.components.socialButton.fontSize', '11px')};
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 5px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
  max-height: 30px;
`;

export const SocialButton = ({
  fillColor,
  text,
  children = '',
  link = '#',
}) => (
  <StyledLink to={link} target="_blank">
    <StyleSocialButton fillColor={fillColor}>
      <div>{text}</div>
      <div>{children}</div>
    </StyleSocialButton>
  </StyledLink>
);

export const Facebook = ({ link }) => (
  <SocialButton fillColor="#4460A0" text="Connect" link={link}>
    <FacebookIcon />
  </SocialButton>
);

export const Twitter = ({ link }) => (
  <SocialButton fillColor="#00ACED" text="Connect" link={link}>
    <TwitterIcon />
  </SocialButton>
);

export const LinkedIn = ({ link }) => (
  <SocialButton fillColor="#007BB6" text="Connect" link={link}>
    <LinkedInIcon />
  </SocialButton>
);

export default {
  SocialButton,
  Facebook,
  Twitter,
  LinkedIn,
};

SocialButton.propTypes = {
  fillColor: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  link: PropTypes.string.isRequired,
};

Facebook.propTypes = {
  link: PropTypes.string.isRequired,
};

Twitter.propTypes = {
  link: PropTypes.string.isRequired,
};

LinkedIn.propTypes = {
  link: PropTypes.string.isRequired,
};
