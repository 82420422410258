import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import H2 from './Elements/Heading2';

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;

  flex-flow: row wrap;
  margin: 0 auto;
  & > img {
    width: 90%;
  }
  @media screen and (min-width: ${prop('theme.screens.large', '1280px')}) {
    margin: auto 0;
    ${ifProp(
    'reversed',
    css`
      flex-flow: row-reverse nowrap;
    `,
    css`
      flex-flow: row nowrap;
      & > img {
        padding-right: 3rem;
      }
    `,
  )}
  & > img {
    width: 20%;
    height: 20%;
    object-fit: cover;
    margin: auto 0;
  }
  }
`;

const StyledContentContainer = styled.div`
  ${ifProp(
    'reversed',
    css``,
    css``,
  )}
  & > p {
    line-height: 1.5;
  }
  padding-top: 2rem;
  @media screen and (min-width: ${prop('theme.screens.large', '1280px')}) {
    padding-top: 0rem;
  }
`;

const TitleAndParagraph = ({
  title, children, reversed, image, alt,
}) => (
  <StyledContainer reversed={reversed}>
    {image && (<img src={image} alt={alt} />)}
    <StyledContentContainer reversed={reversed}>
      <H2>{title}</H2>
      <p>{children}</p>
    </StyledContentContainer>
  </StyledContainer>
);

export default TitleAndParagraph;
