import React, { Component } from 'react';
// import { Link } from 'gatsby';
import {Link} from "@allthingswww/client-act-shared"
import Image from '../ImageMdx';
import { ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { getStyles } from 'masterpiece-ui';

import './Nav.css';

/* Marcus TODO eventually refactor out css to styled components & React Hooks, & Components */

const StyledNavItem = styled.li`
  ${getStyles('Nav.NavItem')()}
  
  ${ifProp(
  { isMobile: true },
  css`
  ${getStyles('Nav.MobileNavItemNavItem')()}
  `
)}
`;

const StyledNavItemLink = styled(Link)`
  
  ${getStyles('Nav.NavItemLink')()}

  ${ifProp(
    { isMobile: true },
    css`
    ${getStyles('Nav.MobileNavItemLink')()}

    `
  )}
`;

const StyledNavItemTitle = styled.a`
  
  ${getStyles('Nav.NavItemTitle')()}
  ${ifProp(
    { isMobile: true },
    css`
      ${getStyles('Nav.MobileNavItemTitle')()}
      
    `
  )}
`;

const StyledSubNav = styled.div`
  ${getStyles('Nav.SubNav')()}
  ${ifProp(
    { isMobile: true },
    css`
      ${getStyles('Nav.MobileSubNav')()}
    `
  )}
`;

const StyledSubNavItemLink = styled(Link)`
  
  ${getStyles('Nav.SubNavItemLink')()}
  ${ifProp(
    { isMobile: true },
    css`
      ${getStyles('Nav.MobileSubNavItemLink')()}
    `
  )}
`;

const StyledSubNavTitleLink = styled(Link)`

  ${getStyles('Nav.SubNavTitleLink')()}
  ${ifProp(
    { isMobile: true },
    css`
      ${getStyles('Nav.MobileSubNavTitleLink')()}
    `
  )}
`;

const StyledSubNavItem = styled.li`
    ${getStyles('Nav.SubNavItem')()}
    ${ifProp(
      { isMobile: true },
      css`
        ${getStyles('Nav.MobileSubNavItem')()}
      `
    )}
`;

export default class NavItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
      activeSubNavItems: [],
      isMenu: false,
      isMouseDown: false,
    };

    this.navClassName = this.props.isMobileMenu ? 'MobileNav' : 'Nav';
  }

  // this is used to find the needed parents for the keyboard navigation
  getClosestParent(elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          const matches = (
            this.document || this.ownerDocument
          ).querySelectorAll(s);
          let i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  }

  handleMouseEvent(isMobileMenu) {
    if (!isMobileMenu) {
      this.setState((prevState) => ({
        isActive: !prevState.isActive,
      }));
    }
  }

  handleOnMouseDown = (e) => {
    this.setState((prevState) => ({
      isMouseDown: true,
    }));
  };

  handleOnMouseUp = (e) => {
    this.setState((prevState) => ({
      isMouseDown: false,
    }));
  };

  handleOnBlur = (e) => {
    if (!this.state.isMouseDown) {
      // This prevents a mouseDown race with onClick that causes the menu to toggle
      this.handleMenuToggle(e);
    }
  };

  handleMenuToggle = (e) => {
    const eventType = e.type;

    this.setState((prevState) => {
      if (
        !prevState.isActive ||
        (prevState.isActive && eventType !== 'focus')
      ) {
        // This prevents focus events causing menu to close. This was happening with keyboard tiggers
        return { isActive: !prevState.isActive };
      }
    });
  };

  // KeyDown relies on role
  handleKeyDown = (e, isMobileMenu) => {
    const navItemClass = `${this.navClassName}-NavItem`;
    const navItemLinkClass = `${this.navClassName}-NavItemLink`;
    const subNavClass = `${this.navClassName}-SubNav`;
    const subNavBannerLinkClass = `${this.navClassName}-SubNavBannerLink`;
    const subNavTitleLink = `${this.navClassName}-SubNavTitleLink`;
    const subNavLinkClass = `${this.navClassName}-SubNavItemLink`;

    if (e.target.classList.contains(navItemClass)) {
      if (e.keyCode === 13 || e.keyCode === 32) {
        /* enter or space key */
        e.preventDefault();
        e.stopPropagation();
        if (e.target.getElementsByClassName(navItemLinkClass)[0]) {
          e.target.getElementsByClassName(navItemLinkClass)[0].focus();
        } else if (e.target.getElementsByClassName(subNavTitleLink)[0]) {
          e.target.getElementsByClassName(subNavTitleLink)[0].focus();
        }
      }

      if (e.keyCode === 40) {
        /* down arrow */
        e.preventDefault();
        e.stopPropagation();
        if (e.target.getElementsByClassName(navItemLinkClass)[0]) {
          e.target.getElementsByClassName(navItemLinkClass)[0].focus();
        } else if (e.target.getElementsByClassName(subNavTitleLink)[0]) {
          e.target.getElementsByClassName(subNavTitleLink)[0].focus();
        }
      }

      if (e.keyCode === 38) {
        /* up arrow */
        e.preventDefault();
        e.stopPropagation();
        if (e.target.previousSibling) {
          e.target.previousSibling.focus();
        }
      }
    } else if (e.target.classList.contains(navItemLinkClass)) {
      if (e.keyCode === 40 || e.keyCode === 38) {
        /* down or up arrow */
        e.preventDefault();
        e.stopPropagation();
        const menuLinks = e.target.parentNode.querySelectorAll(
          `.${subNavLinkClass}, .${subNavBannerLinkClass}, .${subNavTitleLink}`
        )[0];

        if (e.keyCode === 40) {
          /* down arrow */ if (menuLinks) {
            menuLinks.focus();
          } else {
            if (
              this.getClosestParent(e.target, '.' + navItemClass).nextSibling
            ) {
              this.getClosestParent(
                e.target,
                '.' + navItemClass
              ).nextSibling.focus();
            }
          }
        } else {
          /* up arrow */
          if (
            this.getClosestParent(e.target, '.' + navItemClass).previousSibling
          ) {
            this.getClosestParent(
              e.target,
              '.' + navItemClass
            ).previousSibling.focus();
          }
        }
      }
    } else if (
      e.target.classList.contains(subNavLinkClass) ||
      e.target.classList.contains(subNavBannerLinkClass) ||
      e.target.classList.contains(subNavTitleLink)
    ) {
      if (e.keyCode === 40 || e.keyCode === 38) {
        /* down or up arrow */
        e.preventDefault();
        e.stopPropagation();

        // get parent node of subNav
        const subNavParent = this.getClosestParent(e.target, '.' + subNavClass);

        // get the subNavLink nodeList and convert into array
        const subNavLinksArray = Array.prototype.slice.call(
          subNavParent.querySelectorAll(
            `.${subNavLinkClass}, .${subNavBannerLinkClass}, .${subNavTitleLink}`
          )
        );

        // get index of current target
        const currentSubNavLinkIndex = subNavLinksArray.indexOf(e.target);

        if (e.keyCode === 40) {
          /* down arrow */
          // get the next link target. If last item, move to next navItem.
          const nextSubNavLinkIndex =
            currentSubNavLinkIndex + 1 === subNavLinksArray.length
              ? -1
              : currentSubNavLinkIndex + 1;

          nextSubNavLinkIndex >= 0
            ? subNavLinksArray[nextSubNavLinkIndex].focus()
            : subNavParent.parentNode.nextSibling.focus();
        } else {
          /* up arrow */
          // get the next link target. If first item, move to current navItemLink.
          const prevSubNavLinkIndex =
            currentSubNavLinkIndex >= 0 ? currentSubNavLinkIndex - 1 : -1;

          if (prevSubNavLinkIndex >= 0) {
            subNavLinksArray[prevSubNavLinkIndex].focus();
          } else {
            if (
              this.getClosestParent(
                e.target,
                '.' + navItemClass
              ).querySelectorAll('.' + navItemLinkClass)[0]
            ) {
              this.getClosestParent(e.target, '.' + navItemClass)
                .querySelectorAll('.' + navItemLinkClass)[0]
                .focus();
            } else if (
              this.getClosestParent(
                e.target,
                '.' + navItemClass
              ).querySelectorAll('.' + subNavTitleLink)[0]
            ) {
              this.getClosestParent(e.target, '.' + navItemClass)
                .querySelectorAll('.' + subNavTitleLink)[0]
                .focus();
            }
          }
        } /* end of up arrow */
      } /* end of up and down arrow */
    }
  };

  renderSubNavItems(subNavData, isMobileMenu) {
    const subNavItems = subNavData.subNavOrder.map(
      (navOrderItem, navItemIndex) => {
        const { title, slug, url, target: subNavTargetHTMLAtributeValue } = subNavData.subNavItems[navOrderItem];

        const to = url || `/${slug}`;

        return (
          <StyledSubNavItem
            key={navOrderItem}
            isMobile={isMobileMenu}
            className={`${this.navClassName}-SubNavItem`}
            tabindex={navItemIndex}
          >
            <StyledSubNavItemLink
              isMobile={isMobileMenu}
              className={`${this.navClassName}-SubNavItemLink`}
              to={to}
              target={subNavTargetHTMLAtributeValue}
            >
              {title}
            </StyledSubNavItemLink>
          </StyledSubNavItem>
        );
      }
    );

    return (
      <ul className={`${this.navClassName}-SubNavItemList`}>{subNavItems}</ul>
    );
  }

  renderBannerItem(banner) {
    if (this.props.isMobileMenu) {
      return null;
    }

    return (
      <div className={`${this.navClassName}-SubNavBanner`}>
        <Link
          className={`${this.navClassName}-SubNavBannerLink`}
          to={`/${banner.slug}`}
        >
          <Image
            className={`${this.navClassName}-SubNavBannerImage`}
            src={banner.image}
            alt=""
            width="154"
            height="85"
          />
        </Link>
        <span className={`${this.navClassName}-SubNavBannerTitle`}>
          {banner.title}
        </span>
        <span className={`${this.navClassName}-SubNavBannerTitlesubtitle`}>
          {banner.subtitle}
        </span>
      </div>
    );
  }

  render() {
    const { isActive } = this.state;
    const {
      title,
      slug,
      hasSubNav,
      banner,
      navData,
      isMobileMenu,
      navItem,
      url,
      target = ""
    } = this.props;

    this.navClassName = this.props.isMobileMenu ? 'MobileNav' : 'Nav';
    const navTitleClass = isActive ? 'is-open' : 'is-closed';
    const to = url || `/${slug}`;
    return (
      <StyledNavItem
        tabIndex={0}
        className={`${this.navClassName}-NavItem`}
        onMouseEnter={(e) => this.handleMouseEvent(isMobileMenu)}
        onMouseLeave={(e) => this.handleMouseEvent(isMobileMenu)}
        onKeyDown={(e) => this.handleKeyDown(e, this.navClassName)}
        onFocus={this.handleMenuToggle}
        onBlur={this.handleOnBlur}
        onMouseDown={this.handleOnMouseDown}
        onMouseUp={this.handleOnMouseUp}
        aria-haspopup={hasSubNav}
        aria-expanded={isActive}
        isMobile={isMobileMenu}
      >
        {isMobileMenu && hasSubNav ? (
          <StyledNavItemTitle
          id={`Title_${slug}`}
          isMobile={isMobileMenu}
            className={`${this.navClassName}-NavItemTitle ${navTitleClass}`}
          >
            {title}
          </StyledNavItemTitle>
        ) : (
          <StyledNavItemLink
            tabIndex={0}
            isMobile={isMobileMenu}            
            className={`${this.navClassName}-NavItemLink`}
            to={to}
            id={`Link_${slug}`}
            target={target}
          >
            {title}
          </StyledNavItemLink>
        )}
        {hasSubNav && isActive ? (
          <StyledSubNav
            id={`SubNav_${slug}`}
            isMobile={isMobileMenu}
            className={`${this.navClassName}-SubNav`}
          >
            {isMobileMenu && hasSubNav ? (
              <StyledSubNavTitleLink
                id={`SubNavTitleLink_${slug}`}
                tabIndex={0}
                isMobile={isMobileMenu}
                className={`${this.navClassName}-SubNavTitleLink`}
                to={`/${slug}`}
              >
                {title}
              </StyledSubNavTitleLink>
            ) : null}
            {banner && banner.position === 'left'
              ? this.renderBannerItem(banner)
              : null}
            {hasSubNav ? this.renderSubNavItems(navItem.subNav, isMobileMenu) : null}
            {banner && banner.position === 'right'
              ? this.renderBannerItem(banner)
              : null}
          </StyledSubNav>
        ) : null}
      </StyledNavItem>
    );
  }
}
