import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledNumber = styled.a`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
`;

const NavbarRightSection = ({ text, className }) => (
  <StyledContainer className={className}>
    <StyledNumber href={`tel:${text}`} target="_blank" rel="noreferrer">
      {text}
    </StyledNumber>
  </StyledContainer>
);

export default NavbarRightSection;
