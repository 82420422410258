import React from 'react';
import styled from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const StyledH1 = styled.h1`
  font-size: ${prop('theme.heading.h1.fontSize', '50px')};
  color: ${ifProp('color', prop('color', 'white'), prop('theme.textStyles.heading.color', '#0F0F3A'))};
  font-family: ${prop('theme.textStyles.heading.h1.fontFamily', 'Montserrat')};
  font-weight: ${prop('theme.textStyles.heading.h1.fontWeight', "'600'")};
  margin-bottom: 2rem;
  @media screen and (max-width: ${prop('theme.screens.small', '320px')}) {
    font-size: ${prop('theme.heading.h1.fontSize', '50px')};
  }
`;

const H1 = ({
  children, color,
  className,
}) => <StyledH1 color={color} className={className}>{children}</StyledH1>;

export default H1;
