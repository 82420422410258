import React from 'react';
import GenerateIcon from '../../tools/generateSvg';

const FacebookIcon = () => (
  <GenerateIcon>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.67893 16.5H1.01589C0.528037 16.5 0.132812 16.1045 0.132812 15.6169V1.38307C0.132812 0.895283 0.528099 0.5 1.01589 0.5H15.2498C15.7375 0.5 16.1328 0.895283 16.1328 1.38307V15.6169C16.1328 16.1046 15.7374 16.5 15.2498 16.5H11.1726V10.3039H13.2523L13.5637 7.8892H11.1726V6.34755C11.1726 5.64843 11.3667 5.172 12.3692 5.172L13.6479 5.17144V3.0117C13.4268 2.98227 12.6677 2.91652 11.7847 2.91652C9.94109 2.91652 8.67893 4.04184 8.67893 6.1084V7.8892H6.59385V10.3039H8.67893V16.5Z"
      fill="black"
    />
    <mask
      id="facebookIconMask"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="17"
      height="17"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.67893 16.5H1.01589C0.528037 16.5 0.132812 16.1045 0.132812 15.6169V1.38307C0.132812 0.895283 0.528099 0.5 1.01589 0.5H15.2498C15.7375 0.5 16.1328 0.895283 16.1328 1.38307V15.6169C16.1328 16.1046 15.7374 16.5 15.2498 16.5H11.1726V10.3039H13.2523L13.5637 7.8892H11.1726V6.34755C11.1726 5.64843 11.3667 5.172 12.3692 5.172L13.6479 5.17144V3.0117C13.4268 2.98227 12.6677 2.91652 11.7847 2.91652C9.94109 2.91652 8.67893 4.04184 8.67893 6.1084V7.8892H6.59385V10.3039H8.67893V16.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#facebookIconMask)">
      <rect x="0.132812" y="0.5" width="16" height="16" fill="white" />
    </g>
  </GenerateIcon>
);

export default FacebookIcon;
