import React from 'react';
import styled from 'styled-components';
import { Image } from 'masterpiece-ui';
import { prop, ifProp } from 'styled-tools';

const StyledImage = styled(Image)`
  max-width: 100%;
  ${ifProp('float', `float: ${prop('float', 'left')}`)}

  margin: ${prop('margin', '')};
`;
const ImageContainer = styled.div`
  width: 100%;
  ${ifProp('center', 'text-align: center;')}
`;

const imageSizes = [
  '320',
  '450',
  '640',
  '750',
  '800',
  '900',
  '1000',
  '1200',
  '1500',
  '1600',
  '2000',
]; // image sizes used for image source sets

const checkIsUploadcare = (src) => typeof src === 'string' && src.includes('ucarecdn.com');

const ImageMDX = (props) => {
  const {
    className,
    alt = 'image',
    src,
    height,
    width,
    extension = 'jpg',
    format = 'auto',
    quality = 'best',
    center = false,
    float = 'left',
    Wrapper = ImageContainer,
  } = props;
  let srcSet = '';

  /* create source set for images */
  if (checkIsUploadcare(src)) {
    srcSet = imageSizes.map((size) => `${src}-/progressive/yes/-/format/${format}/-/preview/${size}x${size}/-/quality/${quality}/${size}.${extension} ${size}w`);
  }

  return (
    <Wrapper center={center}>
      <StyledImage
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        float={float}
        className={className}
        alt={alt}
        src={src}
        srcSet={srcSet}
        height={!height && !width ? '100%' : height}
        width={!height && !width ? '100%' : width}
      />
    </Wrapper>
  );
};
export default ImageMDX;
