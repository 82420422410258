import * as masterPieceUI from 'masterpiece-ui';
import styled /* , { css } */ from 'styled-components';
import '@fontsource/piazzolla';

const StyledH1 = styled(masterPieceUI.H1)`
  font-family: piazzolla;
  font-size: 50px;
  color: ${(props) => (props.light ? 'white' : '#0F0F3A')};
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  @media (max-width: 400px) {
    font-size: 35px;
  }
`;

export default StyledH1;
