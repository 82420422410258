import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import NavItem from './NavItem';
import Logo from '../Logo';
import NavRight from './NavBarRightSection';
// import manWithHammerImage from '../../static/images/manWithHammer.svg';

const phoneNumber = '(520) 624-3002';

const StyledMobileViewMenu = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  & li {
    margin-bottom: 10px;
  }
`;

const StyledMenu = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  position: fixed;
  z-index: 100;
  /* background: url({manWithHammerImage}); */
  background-size: contain;
  background-color: white;
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  background-position: right 0rem top 15rem;
  @media (min-width: ${prop('theme.screens.mobile', '820px')}) {
    display: none;
  }
`;

const StyledMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1rem;
  flex-grow: 1;
`;
const StyledNav = styled.div`
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const StyledHamburgerCloseIcon = styled.div`
  margin-top: 2.3rem;
`;

const MobileViewNavBar = ({
  navItems = [],
  handleChangePage,
  currentItem,
  handleToggleNavbar,
  logo,
  hamburgerClose,
}) => (
  <StyledMenu>
    <StyledMenuHeader>
      <Logo height="7rem" width="7rem" alt="My Tucson contractor logo" image={logo} />
      <StyledHamburgerCloseIcon onClick={handleToggleNavbar}>
        <img
          height="35rem"
          width="35rem"
          src={hamburgerClose}
          alt="Hamburger close Icon"
        />
      </StyledHamburgerCloseIcon>
    </StyledMenuHeader>
    <StyledNav>
      <StyledMobileViewMenu>
        {navItems.map((item) => (
          <NavItem
            fontSize="24px"
            item={item}
            isCurrentItem={item.href === currentItem}
            key={item.id}
            handleChangePage={handleChangePage}
          />
        ))}
      </StyledMobileViewMenu>
      <NavRight text={phoneNumber} fontSize="24px" />
    </StyledNav>
  </StyledMenu>
);

export default MobileViewNavBar;
