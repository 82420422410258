/* eslint-disable react/no-danger */
import React from 'react';
import Marked from 'react-markdown';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { withErrorBoundary } from './ErrorBoundary';

const StyledContainer = styled.div`
  width: 800px;
  max-width: 100vw;
  margin: 0 auto;
  & > h1 {
    font-size: 32px;
    color: '#0F0F3A';
    font-family: 'Montserrat', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: '600';
    margin-bottom: 2rem;
  }
`;

const encodeMarkdownURIs = (source = '') => {
  const markdownLinkRegex = /\[(.+)\]\((.+)(".+)\)/g;

  return source.replace(markdownLinkRegex, (match, linkURI) => {
    if (!linkURI) return match;
    const replaced = match.replace(linkURI, encodeURI(linkURI));
    return replaced;
  });
};

const HtmlBlock = ({ value }) => {
  if (value.indexOf('<iframe') !== 0) return value;
  return (
    <div
      className="Content--Iframe"
      dangerouslySetInnerHTML={{
        __html: value,
      }}
    />
  );
};

const Content = ({ source, src, className = '' }) => {
  // accepts either html or markdown
  const htmlSource = source || src || '';
  if (htmlSource.match(/^</)) {
    return (
      <StyledContainer
        className={`Content ${className}`}
        dangerouslySetInnerHTML={{ __html: htmlSource }}
      />
    );
  }

  return (
    <Marked
      className={`Content ${className}`}
      source={encodeMarkdownURIs(source)}
      renderers={{
        html: HtmlBlock,
      }}
    />
  );
};

Content.propTypes = {
  source: PropTypes.string.isRequired,
  src: PropTypes.string,
  className: PropTypes.string,
};

Content.defaultProps = {
  className: '',
  src: '',
};

export default withErrorBoundary(Content);
