import StyledH1 from '../components/StyledH1';

const color = {
  white: '#fff',
  black: '#000',
  silver: '#8A8582',
  secondarySilver: '#E3E1E0',
  blue: '#0F0F3A',
  red: '#DD0000',
};

const h1 = {
  fontSize: '50px',
  fontFamily: 'piazzolla',
  fontWeight: '600',
  margin: '2em 0',
};

const h2 = {
  fontSize: '32px',
  fontFamily: 'piazzolla',
  fontWeight: '600',
  margin: '2em 0',
};
const h3 = {
  fontSize: '35px',
  fontFamily: 'piazzolla',
  fontWeight: '400',
  color: '#444444',
  margin: '2em 0',
};
const heading = {
  color: '#0F0F3A',
  h1,
  h2,
  h3,
};

const textStyle = {
  fontSize: '16px',
  color: '#000',
  fontWeight: 400,
  lineHeight: '1.4',
};

const button = {
  fontSize: '18px',
  color: '#000',
  fontWeight: 'normal',
  lineHeight: '24.51px',
};

const socialButton = {
  fontSize: '11px',
  color: '#fff',
};

const screens = {
  mobile: '576px',
};

const components = {
  button,
  socialButton,
  H1: h1,
  H2: h2,
  H3: h3,
  Nav: {
    color: '#0F0F3A',
    background: 'transparent',
    active: {
      background: '#0F0F3A',
    },
    MenuButton: {
      color: '#0F0F3A',
    },
    MenuButtonActive: {
      color: '#fff',
    },
    NavItem: {
      borderBottom: '2px solid transparent',
      '&:focus': {
        borderBottom: '2px solid #8A8582',
      },
    },
    Nav: {
      color: 'purple',
    },
    NavItemLink: {
      color: '#0F0F3A',
      margin: '15px 12px',
      borderBottom: '2px solid transparent',
      '&:focus': {
        borderBottom: '2px solid #8A8582',
      },
      '&:hover': {
        borderBottom: '2px solid #8A8582',
      },
    },
    NavItemTitle: {
      color: '#0F0F3A',
    },
    SubNav: {
      color: '#0F0F3A',
      border: '1px solid #0F0F3A',
      boxShadow: '2px 2px 2px #0F0F3A',
      background: '#fff',
      padding: '20px 15px',
      width: '400px',
      ':after': {
        height: '17px',
        marginTop: '5px',
      },
    },
    MobileNavItemTitle: {
      color: '#fff',
    },
    MobileSubNav: {
      color: '#fff',
      background: '#0F0F3A',
    },
    MobileNavItemLink: {
      color: '#fff',
      margin: '15px 12px',
      borderBottom: '2px solid transparent',
      '&:focus': {
        borderBottom: '2px solid #8A8582',
      },
      '&:hover': {
        borderBottom: '2px solid #8A8582',
      },
    },
    SubNavItemLink: {
      color: '#0F0F3A',
      margin: '5px 6px',
      borderBottom: '2px solid transparent',
      '&:focus': {
        borderBottom: '2px solid #8A8582',
      },
      '&:hover': {
        borderBottom: '2px solid #8A8582',
      },
    },
    MobileSubNavItemLink: {
      color: '#fff',
    },
    SubNavTitleLink: {
      color: '#fff',
    },
    SubNavItem: {

    },
  },
};

export default {
  color,
  breakpoints: ['812px', '1024px'],
  textStyles: {
    heading,
    textStyle,
  },
  components,
  screens,
};
