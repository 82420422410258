import React from 'react';
import styled from 'styled-components';

const StyledMainContainer = styled.div`
  margin-top: 50px;
  width: 45%;
  background-color: #1d003d;
`;

const StyledPara = styled.p`
  
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
  
  span{
    font-weight: bold;
  }

`;

const descriptionHeader = 'ACT and its members thank you for using our free service.';
const actDisclaimer = 'ACT Disclaimer:';
const disclaimer = ' ACT does not provide construction service or advice. ACT does not endorse any of the referral members. The consumer is solely responsible for interviewing and selecting a suitable service provider. ACT, its agents and employees will not be liable to you for any loss or damages of any nature arising out of your use of ACT referrals.\n';

const DisclaimerSection = () => (
  <StyledMainContainer>
    <StyledPara>
      {descriptionHeader}
      <br />
      {' '}
      <br />
      <span>{actDisclaimer}</span>
      {disclaimer}
    </StyledPara>
  </StyledMainContainer>

);

export default DisclaimerSection;
