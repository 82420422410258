import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledContainer = styled.div`
  flex: none;
  width: 350px;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 15px 40px;
  max-width: 98vw;

  h3 {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0f0f3a;
    margin-bottom: 10px;
    @media all and (max-width: 1280px) {
    margin-right: 10px;
  }
  }

  p {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #444444;
  }
`;
const StyledDate = styled.div`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #0f0f3a;
`;

const EventDetails = ({
  eventTitle, date, eventDescription, slug = '',
}) => (
  <StyledContainer>
    <Link to={`/${slug}`}><h3>{eventTitle}</h3></Link>
    <StyledDate>{date}</StyledDate>
    <p>{eventDescription}</p>
  </StyledContainer>
);

export default EventDetails;
