import React from 'react';
import GenerateIcon from '../../tools/generateSvg';

const TwitterIcon = () => (
  <GenerateIcon>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1328 3.07843C15.5449 3.34615 14.9119 3.52721 14.248 3.6081C14.926 3.19207 15.4463 2.53238 15.6914 1.74654C15.0566 2.13176 14.355 2.412 13.6056 2.56224C13.0083 1.90833 12.1546 1.5 11.2099 1.5C9.39829 1.5 7.92855 3.00716 7.92855 4.86584C7.92855 5.12971 7.95673 5.38588 8.01307 5.63242C5.28489 5.49181 2.86663 4.15318 1.24662 2.11442C0.963945 2.61328 0.802414 3.19207 0.802414 3.80841C0.802414 4.97562 1.38186 6.00608 2.26276 6.60991C1.72558 6.59354 1.21845 6.44041 0.775179 6.19002V6.23143C0.775179 7.86283 1.90683 9.22361 3.40945 9.53178C3.13428 9.61075 2.84409 9.65024 2.5445 9.65024C2.3332 9.65024 2.12659 9.63001 1.92656 9.59053C2.34447 10.9272 3.55595 11.9009 4.99282 11.9269C3.86962 12.8302 2.45341 13.3685 0.916049 13.3685C0.651214 13.3685 0.389196 13.3531 0.132812 13.3223C1.58565 14.2757 3.31178 14.8333 5.16468 14.8333C11.2033 14.8333 14.5044 9.70513 14.5044 5.25683C14.5044 5.11045 14.5015 4.96407 14.4959 4.82057C15.1373 4.34579 15.6942 3.75352 16.1328 3.07843Z"
      fill="black"
    />
    <mask
      id="twitterIconMask"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="17"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1328 3.07843C15.5449 3.34615 14.9119 3.52721 14.248 3.6081C14.926 3.19207 15.4463 2.53238 15.6914 1.74654C15.0566 2.13176 14.355 2.412 13.6056 2.56224C13.0083 1.90833 12.1546 1.5 11.2099 1.5C9.39829 1.5 7.92855 3.00716 7.92855 4.86584C7.92855 5.12971 7.95673 5.38588 8.01307 5.63242C5.28489 5.49181 2.86663 4.15318 1.24662 2.11442C0.963945 2.61328 0.802414 3.19207 0.802414 3.80841C0.802414 4.97562 1.38186 6.00608 2.26276 6.60991C1.72558 6.59354 1.21845 6.44041 0.775179 6.19002V6.23143C0.775179 7.86283 1.90683 9.22361 3.40945 9.53178C3.13428 9.61075 2.84409 9.65024 2.5445 9.65024C2.3332 9.65024 2.12659 9.63001 1.92656 9.59053C2.34447 10.9272 3.55595 11.9009 4.99282 11.9269C3.86962 12.8302 2.45341 13.3685 0.916049 13.3685C0.651214 13.3685 0.389196 13.3531 0.132812 13.3223C1.58565 14.2757 3.31178 14.8333 5.16468 14.8333C11.2033 14.8333 14.5044 9.70513 14.5044 5.25683C14.5044 5.11045 14.5015 4.96407 14.4959 4.82057C15.1373 4.34579 15.6942 3.75352 16.1328 3.07843Z"
        fill="white"
      />
    </mask>
    <g mask="url(#twitterIconMask)">
      <rect x="0.132812" y="0.5" width="16" height="16" fill="white" />
    </g>
  </GenerateIcon>
);

export default TwitterIcon;
