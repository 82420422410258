import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const StyledTextArea = styled.textarea`
  border: 2px solid #777;
  line-height: 20px;
  margin-top: 1rem;
  padding: 12px 6px;
  width: 100%;
  color: ${prop('theme.color.blue')};
  border-bottom: 2px solid ${prop('theme.color.blue')};
  ::placeholder {
    color: ${prop('theme.color.silver', '#8A8582')};
    opacity: 1;
    font-family: 'Montserrat';
    padding-top: 2.2rem;
  }

  :-ms-input-placeholder {
    color: ${prop('theme.color.silver', '#8A8582')};
    font-family: 'Montserrat';
  }

  ::-ms-input-placeholder {
    color: ${prop('theme.color.silver', '#8A8582')};
    font-family: 'Montserrat';
  }
  &:focus {
    outline: none;
  }
  ${(ifProp(
    'required',
    css`
      & ::-webkit-input-placeholder:after {
        content: '*';
      }

      & :-moz-placeholder:after {
        /* Firefox 18- */
        content: '*';
      }

      & ::-moz-placeholder:after {
        /* Firefox 19+ */
        content: '*';
      }

      & :-ms-input-placeholder:after {
        content: '*';
      }
    `,
  ),
  '')}
  @media (min-width: ${prop('theme.screens.mobile', '820px')}) {
    width: 100%;
  }
`;

const TextArea = ({
  rows = 4,
  name,
  isRequired = false,
  placeholder = '',
  handleChange,
  maxLength,
}) => (
  <StyledTextArea
    maxLength={maxLength}
    name={name}
    rows={rows}
    onChange={handleChange}
    required={isRequired}
    placeholder={placeholder}
  />
);

export default TextArea;
