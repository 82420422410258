import React from 'react';
import styled from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const StyledH2 = styled.h2`
  font-size: ${prop('theme.heading.h2.fontSize', '32px')};
  color: ${ifProp(
    'color',
    prop('color', 'white'),
    prop('theme.textStyles.heading.color', '#0F0F3A'),
  )};
  font-family: ${prop('theme.textStyles.heading.h2.fontFamily', 'Montserrat')};
  font-weight: ${prop('theme.textStyles.heading.h2.fontWeight', "'600'")};
  margin: ${(props) => props.margin};
  @media (max-width: 400px) {
    font-size: 25px;
  }
`;

const H2 = ({
  children, color, margin = '2em 0', className,
}) => (
  <StyledH2 color={color} margin={margin} className={className}>
    {children}
  </StyledH2>
);

export default H2;
