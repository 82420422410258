export const topNavItems = [
  { id: 1, name: 'Membership', href: '/arizonansForFairContracting' },
  { id: 2, name: 'Events', href: '/currentEvents' },
  { id: 3, name: 'Resources', href: '/resources' },
  { id: 4, name: 'EZ Permits', href: 'https://ezpermitting.org' },
  { id: 5, name: 'About', href: '/about' },
  { id: 6, name: 'Contact Us', href: '/contact-us' },
];

const footerListResources = {
  header: 'Quick Links',
  id: 'resourceId',
  listItems: [
    {
      name: 'EZ Permits',
      id: '0',
      url: 'https://www.ezpermitting.org/',
    },
    {
      name: 'Membership',
      id: '1',
      url: '/purchase-membership',
    },
    {
      name: 'Events',
      id: '2',
      url: '/currentEvents',
    },
    {
      name: 'Resources',
      id: '3',
      url: '/resources',
    },

  ],
};

const footerListGeneral = {
  header: '',
  id: 'generalId',
  listItems: [
    {
      name: 'About Us',
      id: '0',
      url: '/about',
    },
    {
      name: 'Privacy/Terms of Use',
      id: '1',
      url: '/privacy',
    },
    // {
    //   name: 'Site Map',
    //   id: '2',
    //   url: '/sitemap',
    // },
    {
      name: 'Staff',
      id: '3',
      url: '/staff',
    },
  ],
};

const footerListCompanyInformation = {
  header: 'Association Information',
  id: 'associationInformationId',
  listItems: [
    {
      label: 'Tel: ',
      name: '(520) 624-3002',
      url: 'tel:(520) 624-3002',
      id: '0',
    },
    {
      label: 'Fax: ',
      name: '(520) 624-3049',
      url: 'tel:(520) 624-3049',
      id: '1',
    },
    {
      name: '',
      id: '2',
    },
    {
      name: '4015 E Paradise Falls Dr Suite 132',
      id: '3',
      url: 'https://www.google.com/maps/place/4015+E+Paradise+Falls+Dr+STE+132,+Tucson,+AZ+85712/@32.2708026,-110.9093377,17z/data=!3m1!4b1!4m5!3m4!1s0x86d671fc3edeb927:0x38f95a6e99272f5c!8m2!3d32.2708026!4d-110.907149',
    },
    {
      name: 'Tucson, AZ 85712',
      id: '4',
      url: 'https://www.google.com/maps/place/4015+E+Paradise+Falls+Dr+STE+132,+Tucson,+AZ+85712/@32.2708026,-110.9093377,17z/data=!3m1!4b1!4m5!3m4!1s0x86d671fc3edeb927:0x38f95a6e99272f5c!8m2!3d32.2708026!4d-110.907149',
    },
  ],
};

export const footerNavItems = {
  footerListCompanyInformation,
  footerListGeneral,
  footerListResources,
};
