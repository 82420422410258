import React from 'react';
import Helmet from 'react-helmet';

import favicon from '../../static/icons/favicon.ico';

const Favicon = () => (
  <Helmet>
    <link rel="icon" href={favicon} />
  </Helmet>
);

export default Favicon;
