/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

import Link from './Link';

const getColor = ({ theme, color, isDisabled }) => {
  if (isDisabled) {
    return theme.color.silver || '#8A8582';
  }
  switch (color) {
    case 'primary':
      return theme.color.blue || '#0F0F3A';
    case 'secondary':
      return theme.color.red || '#DD0000';
    default:
      return color || '#0F0F3A';
  }
};

const changeButtonPadding = ({ size }) => {
  let padding;
  switch (size) {
    case 'small':
      padding = '0.75rem';
      break;
    case 'medium':
      padding = '1rem';
      break;
    case 'large':
      padding = '1.25rem';
      break;
    default:
      padding = '0rem 1.5rem';
  }
  return padding;
};

const StyledButton = styled.button`
  ${ifProp(
    'variant',
    '',
    css`
      border: 3px solid ${getColor};
    `,
  )};
  ${ifProp('size', css`padding: ${changeButtonPadding};`, css`padding: ${changeButtonPadding};`)}
  background: ${ifProp(
    'variant',
    prop('theme.color.secondarySilver', '#E3E1E0'),
    'transparent',
  )};
  min-height: 40px;
  min-width: 140px;
  text-align: center;
  font-size: ${prop('theme.components.button.fontSize', '18px')};
  color: ${getColor};
  &:hover {
    ${ifProp(
    'isDisabled',
    css`
        cursor: not-allowed;
      `,
    css`
        cursor: pointer;
      `,
  )};
  }
`;

export const Button = ({
  variant,
  href,
  color = 'primary',
  className,
  children,
  size,
  isDisabled = false,
  onClick,
  newWindow,
  handleClick, // Deprecated
  ...remainingProps
}) => {
  if (newWindow) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        <StyledButton
          isDisabled={isDisabled}
          variant={variant}
          color={color}
          href={href}
          size={size}
          className={className}
          {...remainingProps}
        >
          {children}
        </StyledButton>
      </a>
    );
  }
  if (!href) {
    return (
      <StyledButton
        isDisabled={isDisabled}
        onClick={onClick || handleClick}
        variant={variant}
        color={color}
        href={href}
        size={size}
        className={className}
        {...remainingProps}
      >
        {children}
      </StyledButton>
    );
  }
  return (
    <Link to={href}>
      <StyledButton
        className={className}
        size={size}
        variant={variant}
        color={color}
        href={href}
        {...remainingProps}
      >
        {children}
      </StyledButton>
    </Link>
  );
};

export const Primary = ({
  href = '#', children, size, className, ...remainingProps
}) => (
  <Button color="primary" href={href} size={size} className={className} {...remainingProps}>
    {children}
  </Button>
);
export const Secondary = ({
  href = '#', children, size, className, ...remainingProps
}) => (
  <Button color="secondary" href={href} size={size} className={className} {...remainingProps}>
    {children}
  </Button>
);

export default {
  Button,
  Primary,
  Secondary,
};
