import React from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from '@reach/router';

import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import Link from '../Link';

const StyledNavItem = styled.li`
  ${ifProp(
    'isCurrentItem',
    css`
      &:after {
        content: '';
        display: block;
        width: 50%;
        text-align: center;
        border-bottom: 2px solid ${prop('theme.color.blue', '#0F0F3A')};
      }
    `,
    '',
  )}
  ${ifProp(
    'fontSize',
    css`
      font-size: ${prop('fontSize')};
    `,
    '',
  )}
  ${ifProp(
    {
      itemName: 'Emergency',
    },
    css`
      color: ${prop('theme.color.red', '#DD0000')};
    `,
    css`
      color: ${prop('theme.color.blue', '#0F0F3A')};
    `,
  )}
  padding: 0.7rem 0.2rem;
  min-width: max-content;
  text-align: center;

  list-style-type: none;
  &:hover {
    &:after {
      content: '';
      text-align: center;
      display: block;
      border-bottom: 2px solid ${prop('theme.color.silver', '#8A8582')};
    }
  }
  @media (min-width: ${prop('theme.screens.large', '1280px')}) {
    margin-right: 20px;
  }
  @media (min-width: ${prop('theme.screens.xlarge', '2100px')}) {
    margin-right: 20px;
  }
`;

const StyledNavItemContainer = styled.div`
  & > a:focus {
    outline-color: ${prop('theme.color.blue', '#0F0F3A')};
  }
`;

const NavItem = ({ item, fontSize }) => {
  const location = useLocation();
  return (
    <StyledNavItemContainer>
      <Link
        to={item.href}
      >
        <StyledNavItem
          itemName={item.name}
          fontSize={fontSize}
          isCurrentItem={location.pathname === item.id}
        >
          {item.name}
        </StyledNavItem>
      </Link>
    </StyledNavItemContainer>
  );
};

NavItem.propTypes = {
  item: PropTypes.shape({
    href: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default NavItem;
